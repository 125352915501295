<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- Job create section -->
            <section class="Job-create-section section-gap-30">
                <div class="section-title-wrap flex-wrap">
                    <div class="section-title" style="line-height: 1.5">{{ $t("Create a New Job")}}</div>
                    <v-popover offset="10" :disabled="false">
                        <button class="ej__tooltip__button ml-1"> <i class="eicon e-question"></i> </button>
                        <template slot="popover">
                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                            <a v-close-popover href="https://easy.jobs/docs/create-a-new-job-post/" target="_blank">
                                {{ $t(`How to fill out job information?`) }}
                            </a>
                        </template>
                    </v-popover>
                    <div class="d-flex ml-auto text-right">
                        <button class="button warning-button mb-2 ml-2" @click.prevent="jobPreview">{{ $t("Preview")}}</button>
                        <button class="button primary-button mb-2 ml-2 disabled">{{ $t("Customize Fields")}}</button>
                    </div>

                </div>
                <ul class="progress-step-group">
                    <li class="progress-step__wrap">
                        <router-link :to="{name: 'job.create'}" class="progress-step" exact-active-class="progress-step--activated" exact>
                            <div class="progress-step__icon">
                                <div class="eicon e-tick"></div>
                            </div>
                            <div class="progress-step__text">{{ $t("information")}}</div>
                        </router-link>
                    </li>
                    <li class="progress-step__wrap" v-if="company.package_rule.screening">
                        <a href="#" class="progress-step" :class="{'disabled' : !editMode}" exact-active-class="progress-step--activated" exact>
                            <div class="progress-step__icon">
                                <div class="eicon e-tick"></div>
                            </div>
                            <div class="progress-step__text">{{ $t("Screening Questions")}}</div>
                        </a>
                    </li>
                    <li class="progress-step__wrap" v-if="company.package_rule.quiz">
                        <a href="#" class="progress-step" :class="{'disabled' : !editMode}" exact-active-class="progress-step--activated" exact>
                            <div class="progress-step__icon">
                                <div class="eicon e-tick"></div>
                            </div>
                            <div class="progress-step__text">{{ $t("Quiz")}}</div>
                        </a>
                    </li>
                </ul>
                <router-view></router-view>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import {EventBus} from "../extra/event-bus";

const DashboardLayout = () => import("./DashboardLayout");
const ModalRequiredFields = () => import("../components/modal/job/RequiredFields");

import {mapState} from 'vuex';
import {SHOW_JOB_TEMP_PREVIEW} from "../constants/events";

export default {
        components: {
            DashboardLayout,
            ModalRequiredFields,
        },
        data() {
            return {
                currentJob: {},
                editMode: false,
                publishable: false,
                isPublished: false,
                modal: {
                    fade: false,
                    show: false,
                }
            }
        },
        computed: {
            ...mapState(['company']),
        },
        methods: {
            jobPreview() {
                EventBus.$emit(SHOW_JOB_TEMP_PREVIEW);
            }
        },
    }
</script>

<style>
    .disabled {
        pointer-events: none;
    }

</style>
